import { ref } from 'vue';

const messages = ref([]);

export const addToMessagesSofiaPlus = ({ id, topicId, body, isMe, time, topicName }, addToDp = false, isThereMsg = false) => {
  const message = {
    id,
    topicId,
    body,
    isMe,
    time,
    topicName
  };

  messages.value.push(message);

  if (addToDp) {
    console.log('Message added to display:', message);
  }

  if (isThereMsg) {
    console.log('There is a new message');
  }
};

export const getMessages = () => {
  return messages.value;
};
