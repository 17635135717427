<template>
  <div>
    <TopNav :patientName="patientName"/>
    <div class="recorder-page">
      <div v-if="!isConnected" class="text-center mt-5 pt-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Connecting...</span>
        </div>
      </div>
      <div v-else class="recorder-container container text-center mt-3">
        <div class="counter mb-3">{{ formatTime(counter) }}</div>
        <button class="btn btn-lg btn-primary mic-button" @click="toggleRecording">
          <i :class="micIcon"></i>
        </button>
        <div class="controls mt-4">
          <button class="btn btn-warning me-2" @click="saveDraft(transcription)"
            :disabled="isSending || !transcription">
            <span v-if="isSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>Draft</span></button>
          <button class="btn btn-primary" 
                  @click="sendToSofia(transcription)" 
                  :disabled="isSending || !transcription">
            <span v-if="isSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>Send to Sofia</span>
          </button>
        </div>
      </div>

      <!-- Modal -->
      <div v-if="showConfirm" class="modal-overlay">
        <div class="modal-content">
          <h3>Are you sure you want to leave?</h3>
          <p>You have unsent transcription or draft. If you leave, your progress will be lost.</p>
          <div class="modal-buttons">
            <button @click="confirmLeave" class="btn btn-danger">Leave</button>
            <button @click="cancelLeave" class="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
// import { useWebSocket } from '../utils/useWebSocket';
import { useRecorder } from '../utils/useRecord2';
import { useStore } from 'vuex';
import { addToMessagesSofiaPlus } from '../utils/chatMessages';
import {  useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import TopNav from '../layouts/topNav.vue'


const router = useRouter(); 
// const route = useRoute();
const toast = useToast();
const store = useStore();

const language = ref('en');
const { isRecording,transcription, startRecording, stopRecording } = useRecorder(language);
const counter = ref(0);
const micIcon = ref('fa-solid fa-microphone');
let interval = null;
// const topicId = route.query.topicId;
const patientName = computed(() => store.state.patientName);
const DOB = computed(() => store.state.dOB);
const DOC = computed(() => store.state.dOC);
const DOS = computed(() => store.state.dOS);
// const dialogId = "2";
// const token = localStorage.getItem('token');
// const wsUrl = `${process.env.VUE_APP_SOFA_WS}fast/${topicId}/${dialogId}/${patientName.value}`;

const isSending = ref(false);
// const receivedResponse = ref(false);
const showConfirm = ref(false); 

// eslint-disable-next-line no-unused-vars, no-undef
const props = defineProps({
  socket: Object,
  sendMessage: Function,
  handleMessage: Function, // Receive handleMessage as a prop
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['send']);
// const handleMessage = (receivedData) => {
//   if (receivedData.source === 'sofia') {
//     receivedResponse.value = receivedData.response;
//     console.log('Received Sofia response:', receivedData.response);
//   }
// };
const isConnected = computed(() => store.state.websocket.isConnected);
// const { sendMessage, isConnected } = useWebSocket(wsUrl,token, handleMessage);

onMounted(() => {
  // console.log(isConnected.value)
  // if(isConnected.value === false){
  //   connect();
  // }
  // console.log(isConnected.value)
  // store.dispatch('websocket/connect', {
  //   wsUrl,
  //   token,
  //   handleMessage
  // });

  });
const toggleRecording = () => {
  if (isRecording.value) {
    stopRecording();
    isSending.value = true;
    clearInterval(interval);
    interval = null;
    micIcon.value = 'fa-solid fa-microphone';
  } else {
    startRecording();
    interval = setInterval(() => {
      counter.value += 1;
    }, 1000);
    micIcon.value = 'fa-solid fa-pause';
  }
};
watch(transcription, (newValue) => {
  if (newValue) {
    isSending.value = false; 
  }
});

const saveDraft = (messageText) => {
  console.log('Draft saved');
  const trimmedMessage = messageText.trim();
  if (trimmedMessage.length > 0) {
    isSending.value = true;  
    const messagePayload = {
      message: trimmedMessage,
      is_draft: 1,
      category_id: 'conversation between patient and doctor',
      chat_id: 0,
      message_id: '12',
    };
    toast.success("Draft saved");

    store.dispatch('websocket/sendMessage', messagePayload);
    // sendMessage(messagePayload);
    router.push({ path: '/' });
  }
};

const sendToSofia = async (messageText) => {
  const trimmedMessage = messageText.trim();
  if (trimmedMessage.length > 0) {
    isSending.value = true; 
    const messagePayload = {
      message: trimmedMessage,
      is_draft: 0,
      category_id: 'conversation between patient and doctor',
      chat_id: 0,
      message_id: '12',
      patian_name: patientName.value,
      date_of_birth : DOB.value,
      date_of_complain: DOC.value,
      date_of_service:DOS.value
    };

    // sendMessage(messagePayload);
    // store.dispatch('websocket/sendMessage', messagePayload);
    props.sendMessage(messagePayload);
    addToMessagesSofiaPlus({
      id: 0,
      topicId: 0,
      body: transcription.value.trim(),
      isMe: true,
      time: new Date(),
      topicName: 'conversation between patient and doctor',
    }, true, true);
    emit('send');
    // router.push({ name: 'enconter', params: { chatId: topicId }, query: { patientName } });
  }
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

// // Route leave confirmation modal
// router.beforeEach((to, from, next) => {
//   if (!transcription.value || isSending.value) {
//     next();
//   } else {
//     showConfirm.value = true;
//     resolveLeave = next;
//   }
// });

// const confirmLeave = () => {
//   showConfirm.value = false;
//   resolveLeave();
// };

// const cancelLeave = () => {
//   showConfirm.value = false;
// };

// onBeforeUnmount(() => {
//   if (!transcription.value || isSending.value) return;

//   const answer = "You have unsent transcription or draft. Are you sure you want to leave?";
//   if (!answer) {
//     event.preventDefault();
//   }
// });

</script>

<style scoped>
.recorder-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  margin-top: 60px;
}

.recorder-container {
  text-align: center;
}

.counter {
  font-size: 2rem;
  margin-bottom: 20px;
}

.mic-button {
  font-size: 4rem;
  width: 10rem;
  height: 10rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  color: white;
  background-color: #1f86ae;
  border-radius: 50%;
}

.controls {
  display: flex;
  justify-content: center;
}

.nav-header {
  background-color: #1f86ae;
  /* width: 83.3%; */
  left: unset;  
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width:50%;
}

.modal-buttons {
  margin-top: 20px;
}

@media (max-width: 576px) {
  /* .nav-header {
    width: 100%;
    justify-content: space-between;
  } */

  .mic-button {
    font-size: 3rem;
  }

  .counter {
    font-size: 1.5rem;
  }
}
</style>
