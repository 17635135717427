<template>
  <div class="d-flex flex-column align-items-center my-4">
    <router-link :to="`/addEnconter`" class="text-decoration-none" >
      <button  class="add-topic-btn my-3">+ Add New Encounter</button>
    </router-link>
    <ul v-if="topics.length" class="list-group" style="width:100%">
      <li v-for="topic in topics" :key="topic.id" class="list-group-item d-flex justify-content-between align-items-center">
        <router-link  :to="topic.is_draft ? `/record?topicId=${topic.id}` : `/encounter?topicId=${topic.id}`" class="text-decoration-none" @click="(storePatientName(topic.name), goToDetails() )">
          <i v-if="topic.is_draft" class="fa fa-pen-to-square text-info" aria-hidden="true"></i>
          <i v-else class="fa-solid fa-notes-medical text-success" aria-hidden="true"></i>
          <span class="ms-2" >{{ topic.name }}</span>
        </router-link>
        <div class="dropdown">
          <button class="btn btn-white " @click="toggleDropdown(topic.id)">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <ul v-if="topic.showDropdown" class="dropdown-menu">
            <li><a class="dropdown-item"  @click="handleSend(topic, 'pdf')">Send as PDF</a></li>
            <li><a class="dropdown-item"  @click="handleSend(topic, 'docx')">Send as DOCX</a></li>
          </ul>
        </div>
      </li>
    </ul>

    <p v-else>No topics available.</p>

    <paginate class="my-3"
      v-if="topics.length"
      :page-count="totalPages"
      :click-handler="handlePageClick"
      :prev-text="'-'"
      :next-text="'+'"
      :container-class="'pagination'"
      :page-range="1"
      
      :page-class="'page-item'"
      :active-class="'active'"
      :page-link-class="'page-link'"
    />
  </div>
</template>

<script setup>

import { ref, onMounted ,watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Paginate from 'vuejs-paginate-next'; 
import { useToast } from 'vue-toastification';
import {setupJWTInterceptor} from '@/services/axios';

const JWTinterceptor = setupJWTInterceptor();

const store = useStore();
const route = useRoute();
const topics = ref([]);
const error = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const toast = useToast();
const token = localStorage.getItem('token');
const currentView = ref('');


const storePatientName = (name) => {
  // console.log(name)
  store.commit('setPatientName', name);
};

const getTopics = async (page, dialogId) => {
  try {
    const response = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}chat/${dialogId}`, {
      params: { page },
      
    });
    topics.value = response.data.responseData.chats.map(chat => ({
      ...chat,
      selectedOption: '', 
      showDropdown: false 
    }));

    currentPage.value = response.data.responseData.page;
    totalPages.value = response.data.responseData.total_pages;
  } catch (err) {
    if (err.response?.status === 401) {
      await getTopics(token, page, dialogId); 
    } else {
      error.value = err.message;
      // toast.error(err.message)
    }
  }
};


const handlePageClick = (pageNum) => {
  if (pageNum !== currentPage.value) {
    // console.log(pageNum)
    getTopics(pageNum, 2); 
  }
};
watch(
  () => route.query,
  // eslint-disable-next-line no-unused-vars
  async (newQuery, oldQuery) => {
    if (newQuery === '/encounter') return;
    await getTopics(currentPage.value, 2); // Refresh topics when returning
  }
);

onMounted(() => { 
  getTopics(currentPage.value, 2);  
});
const goToDetails = () => {
  currentView.value = 'details';
};
const getSendPdf = async (chatId, type) => {
  try {
    const result = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}send_mail/${chatId}/`, {
      file_type: type,
    });

    toast.success("Mail Sent successfully");
    return result;
  } catch (e) {
    if (e.response) {
      toast.error(`Status code: ${e.response.status}`);
      if (e.response.status === 401) {
        // eslint-disable-next-line no-undef
        return response;
      }
    } else {
      toast.error("Something went wrong");
    }
    return e.response;
  }
};

const handleSend = async (topic, type) => {
  await getSendPdf(topic.id, type);
  topic.showDropdown = false; 
};

const toggleDropdown = (topicId) => {
  topics.value.forEach(topic => {
    if (topic.id === topicId) {
      topic.showDropdown = !topic.showDropdown;
    } else {
      topic.showDropdown = false;
    }
  });
};
</script>

<style scoped>
.topics-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.topic-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.topic-item:hover {
  background-color: #f0f0f0;
}

.topic-link {
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-decoration: none;
  color: #333;
}

.draft-icon,
.encounter-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #007bff;
}

.topic-name {
  flex-grow: 1;
  font-weight: 500;
}

.share-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.share-btn:focus {
  outline: none;
}
.add-topic-btn {
  background-color: #6dc7f0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-topic-btn:hover {
  background-color: #1f86ae;
}

.dots-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
}

.dots-btn:focus {
  outline: none;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu a {
  padding: 10px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

@media (max-width: 480px) {
  .topic-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .topic-name {
    margin-bottom: 10px;
  }

  .share-btn {
    align-self: flex-end;
  }
}
</style>