<template>
  <div class="register container mt-5">
    <div class="row justify-content-center">
      <div >
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mb-4">
        <form @submit.prevent="handleRegister" class="row">
          <div class="mb-3 col-md-6">
            <label for="firstName" class="form-label">First Name</label>
            <input type="text" id="firstName" v-model="firstName" @blur="touched.firstName = true" class="form-control" required />
            <small v-if="touched.firstName && !firstName" class="text-danger">First Name is required.</small>
          
          </div>
          <div class="mb-3 col-md-6">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" id="lastName" v-model="lastName" @blur="touched.lastName = true" class="form-control" required />
            <small v-if="touched.lastName && !lastName" class="text-danger">Last Name is required.</small>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" v-model="email"  @blur="touched.email = true" class="form-control" required />
            <small v-if="touched.email && !email" class="text-danger">Email is required.</small>
            <small v-else-if="touched.email && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone Number</label>
            <input type="tel" id="phone" v-model="phone" @blur="touched.phone = true"  class="form-control" required />
            <small v-if="touched.phone && !phone" class="text-danger">Phone is required.</small>
            <small v-else-if="touched.phone && !isPhoneValid" class="text-danger">Phone Number must be at least 10 characters.</small>
          </div>
          <div class="mb-3 col-md-6">
            <label for="lic" class="form-label">LIC</label>
            <input type="text" id="lic" v-model="lic" @blur="touched.lic = true" class="form-control" required />
            <small v-if="touched.lic && !lic" class="text-danger">LIC is required.</small>
          </div>
          <div class="mb-3 col-md-6">
            <label for="npi" class="form-label">NPI</label>
            <input type="text" id="npi" v-model="npi" @blur="touched.npi = true" class="form-control" required />
            <small v-if="touched.npi && !npi" class="text-danger">NPI is required.</small>
            
          </div>
          <div class="mb-3">
            <label for="state" class="form-label">State</label>
            <select v-model="selectedState" @blur="touched.selectedState = true" id="state" class="form-select" required>
              <option value="" disabled>Select a state</option>
              <option v-for="state in states" :key="state.name" :value="state.name">
                {{ state.name }}
              </option>
            </select>
            <small v-if="touched.selectedState && !selectedState" class="text-danger">State is required.</small>

          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <div class="input-group">
              <input   :type="showPassword ? 'text' : 'password'" id="password" @blur="touched.password = true" v-model="password" class="form-control" required />
              <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                  <i @click="toggleShow" class="fas eye-shape"
                    :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </span>
              </div>
            </div>
            <small v-if="touched.password && !password" class="text-danger">Password is required.</small>
            <small v-else-if="touched.password && password.length < 6" class="text-danger">Password must be at least 6 characters.</small>

          </div>

          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm Password </label>
            <div class="input-group">
            <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" @blur="touched.confirmPassword = true" class="form-control" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                  <i @click="toggleShow" class="fas eye-shape"
                    :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </span>
              </div>
            </div>
            <small v-if="touched.confirmPassword && !confirmPassword" class="text-danger">Confirm Password is required.</small>
            <small v-else-if="touched.confirmPassword && confirmPassword !== password" class="text-danger">Passwords do not match.</small>
          </div>

          <div class="mb-3">
            <label for="clinicCode" class="form-label">Clinic Code</label>
            <input type="text" id="clinicCode" v-model="clinicCode" @blur="touched.clinicCode = true" class="form-control" required />
            <small v-if="touched.clinicCode && !clinicCode" class="text-danger">Clinic Code is required.</small>
          </div>
          <div class="mb-3">
            <label for="specialty" class="form-label">Select your specialty</label>
            <select v-model="selectedSpecialty"  @blur="touched.selectedSpecialty = true" id="specialty" class="form-select" required>
              <option value="" disabled>Select a specialty</option>
              <option v-for="specialty in specialties" :key="specialty.id" :value="specialty.id">
                {{ specialty.name }}
              </option>
            </select>
            <small v-if="touched.selectedSpecialty && !selectedSpecialty" class="text-danger">Specialty is required.</small>

          </div>
          <div class="mb-3">
            <a href="https://ensofia.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              By registering, you agree to our terms and conditions (Here)
            </a>
          </div>
          <button type="submit" class="btn btn-primary w-100" :disabled="!isFormValid || loading">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import states from '@/assets/states.json';

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const phone = ref('');
const lic = ref('');
const npi = ref('');
const touched = ref({
  firstName: false,
  lastName: false,
  email: false,
  password: false,
  confirmPassword: false,
  phone:false,
  lic:false,
  npi:false,
  clinicCode:false,
  selectedSpecialty:false,
  selectedState:false,
});
const clinicCode = ref('');
const selectedSpecialty = ref('');
const specialties = ref([]);
const selectedState = ref('');
// const states = ref([]);
const loading = ref(false);
const toast = useToast();
const router = useRouter();
const store = useStore()
let showPassword = ref(false);

const fetchSpecialties = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_SOFA_API}getSpecialtyList`);
    specialties.value = response.data;
  } catch (error) {
      toast.error('Error fetching specialties:', error);
      console.error('Error fetching specialties:', error);
  }
};

onMounted(() => {
  fetchSpecialties();
});
const toggleShow = () => {
            showPassword.value = !showPassword.value;
        };

const isFormValid = computed(() => {
  return firstName.value && lastName.value && isEmailValid.value &&  password.value.length >= 6 &&
  password.value === confirmPassword.value && isPhoneValid.value && lic.value && npi.value && selectedState.value && clinicCode.value && selectedSpecialty.value;
});
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email.value);
});


// const isPasswordValid = computed(() => {
//   return password.value.length >= 6;
// });
const isPhoneValid = computed(() => {
  return phone.value.length == 10;
});

const handleRegister = async () => {
  loading.value = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_SOFA_API}register`, {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      phone: phone.value,
      lic: lic.value,
      npl: npi.value,
      clinic_code: clinicCode.value,
      specialty_id: selectedSpecialty.value,
      state: selectedSpecialty.value,
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log(response.data);
    store.commit('setPhone', response.data.responseData.phone);
    router.push('/otp');
  } catch (error) {
    const statusCode = error.response?.status;
    if (statusCode === 409) {
      error.value("The account already exists");
      toast.error("The account already exists");
    } else if (statusCode === 201 ||statusCode === 200) {
      console.log("User created successfully");
      toast.success("User created successfully");
    } else {
      error.value(`Server error ${statusCode}`);
      toast.error(`Server error ${statusCode}`);
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.register {
  max-width: 400px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
</style>
