// src/utils/useWebSocket.js
import { ref, onMounted, onUnmounted } from 'vue';
import { useToast } from 'vue-toastification';

const socket = ref(null);
const isConnected = ref(false);

export function useWebSocket(wsUrl, token, handleMessage) {
  const toast = useToast();

  const connect = () => {
    if (isConnected.value) return; // Prevent reconnect if already connected

    socket.value = new WebSocket(wsUrl);

    socket.value.addEventListener('open', () => {
      console.log('WebSocket connection established.');
      isConnected.value = true;

      if (token) {
        socket.value.send(JSON.stringify({ token }));
      } else {
        console.error('Token is missing!');
      }
    });

    socket.value.addEventListener('message', (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        console.log('Received message:', receivedData);
        if (handleMessage) {
          console.log("aaa")
          handleMessage(receivedData);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
        toast.error('Error parsing WebSocket message:', error);
      }
    });

    socket.value.addEventListener('close', (event) => {
      console.log(`WebSocket closed: Code ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
      isConnected.value = false;
    });

    socket.value.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
      toast.error('WebSocket error:', error);
    });
  };

  const sendMessage = (message) => {
    if (isConnected.value && socket.value.readyState === WebSocket.OPEN) {
      socket.value.send(JSON.stringify(message));
      console.log('Message sent:', message);
    } else {
      toast.warning('WebSocket is not open. Unable to send message.');
    }
  };

  const closeConnection = () => {
    if (isConnected.value && socket.value) {
      socket.value.close();
      isConnected.value = false;
      console.log("WebSocket connection closed");
    }
  };

  onMounted(connect);
  onUnmounted(closeConnection);

  return {
    socket,
    isConnected,
    sendMessage,
    closeConnection,
    connect
  };
}
