<template >
  <div v-if="currentView === 'record'">
      <RecordPage
        :socket="socket"
        :sendMessage="sendMessage"
        :handleMessage="processMessage"
        @send="goToDetails" 
      />
    </div>
  <div v-else-if="currentView === 'details'"> 
    <TopNav :patientName="patientName"  />
    <div style="margin:5rem 0;">
      <div ref="chatContainer" style="max-height: 70vh; overflow-y: auto;">
        <div v-for="message in data.responseData" :key="message.id" class="  mt-3" :data-id="message.id">
          <div class="card-body">
            <div :class="['message-body', message.isUser ? 'message-color-user' : 'message-color-sofia', 'text-white', 'p-3', 'rounded']">
              <!-- <p>{{ message.message }}</p> -->
              <p v-html="formatMessage(message.message)"></p>
            <p class="timestamp">{{ new Date(message.created_at).toLocaleString() }}</p>
              <div v-if="!message.isUser" class="message-actions d-flex gap-2 mt-2">
                <button @click="toggleSpeak(message)" class="btn-color">
                  <i v-if="message.isLoading" class="fa-solid fa-spinner fa-spin"></i>
                  <i v-else :class="message.isSpeaking ? 'fa-solid fa-pause' : 'fa-solid fa-volume-up'"></i>
                </button>
                <button @click="copyToClipboard(message.message)" class="btn-color">
                  <i class="fas fa-copy"></i>
                </button>
                <button @click="sendPdf(message.message)" class="btn-color">
                  <i class="fas fa-file-pdf"></i>
                </button>
                <button @click="shareMessage(message.message)" class="btn-color">
                  <i class="fas fa-share-alt"></i>
                </button>
              </div>
            </div>
          </div>
          
        </div>
        <div v-if="loadingMessage" class="text-center mt-3">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    </div>
    

    <div class="button-container d-flex flex-wrap gap-2 justify-content-center  fixed-bottom bg-light py-4 col-12 col-md-10  ">
      <button
      v-for="btn in transformedButtons"
      :key="btn.original"
      @click="handleButtonClick(btn.label)"
      class="btn btn-info text-white"
    >
      {{ btn.label }}
    </button>
    </div>

    
  </div>
</template>

<script setup>
import { ref, onMounted ,computed,watch, nextTick , onUnmounted} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {setupJWTInterceptor} from '@/services/axios';
import TopNav from '../layouts/topNav.vue';
import { useSpeak } from '../utils/useSpeech'
import RecordPage from './RecordPage.vue';


const JWTinterceptor = setupJWTInterceptor();
// eslint-disable-next-line no-unused-vars
const { speakText, stopSpeaking , isLoading} = useSpeak('es');

const store = useStore();
const route = useRoute();
const topicId =  route.query.topicId; 
const data = ref({ responseData: [] }); 
const loading = ref(true);
const text = ref(''); 
const dialogId ="2";
const token = localStorage.getItem('token')
const patientName = computed(() => store.state.patientName);
const wsUrl  = `${process.env.VUE_APP_SOFA_WS}fast/${topicId}/${dialogId}/${patientName.value}`;
const sentMessages = ref([]); 
const receivedResponses = ref([]); 
const toast = useToast();
const chatContainer = ref(null);
const buttons = ref([]); 
const transformedButtons = ref([]);
const currentView = ref('details');
const socket = computed(() => store.state.websocket.socket);
const loadingMessage = ref(false);

const sendMessage = (message) => {
  store.dispatch('websocket/sendMessage', message);
  loadingMessage.value = true;
  // console.log(loadingMessage.value)
};

// Function to process incoming WebSocket messages
const processMessage = (receivedData) => {
  console.log("Sdf",receivedData.source)
  if (receivedData.source === 'sofia') {
    // receivedResponses.value = receivedData.response;
    receivedResponses.value.push(receivedData.response);
    console.log('Received Sofia response:', receivedData.response);
  }
  // if (receivedData.source === 'sofia') {
  //   console.log('Updating UI with new message:', receivedData);
  //   messages.value.push(receivedData); // Update the messages displayed
  // }
  else if (receivedData && receivedData.message) {
    const receivedMessage = {
      id: new Date().getTime(), 
      message: receivedData.responseData.message, 
      created_at: new Date().toISOString(), 
      isUser: false,
    };

    if (data.value && data.value.responseData) { 
      data.value.responseData.push(receivedMessage); 
    } else {
      data.value = {
        responseData: [receivedMessage],
      };
    }
    console.log('Message received via WebSocket:', receivedMessage); 
    receivedResponses.value.push(receivedMessage.message);
  } else {
    console.log('Received unrecognized WebSocket message:', receivedData); 
  }
  loadingMessage.value = false;
};




onMounted(() => {
  // console.log(topicId)
  if (route.query.view === 'record') {
    currentView.value = 'record';
  }
getBtn()
store.dispatch('websocket/connect', {
    wsUrl,
    token,
    handleMessage: processMessage,
  });
if (topicId && token) {
  getTopicChat(topicId);
} else {
  toast.error('Chat ID or Access Token is missing');
  console.error('Chat ID or Access Token is missing'); 
}
scrollToBottom();
});
const formatMessage = (message) => {
  return message.replace(/\n/g, '<br>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

};

const goToDetails = () => {
  currentView.value = 'details';
  getTopicChat(topicId);
};


const getTopicChat = async (topicId) => {
  // console.log(topicId)
  try {
    const response = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}chat/${topicId}/messages`);
    data.value = response.data; 
    console.log('Response received in details:', response.data); 
    receivedResponses.value.push(JSON.stringify(response.data.responseData.message)); 
  } catch (e) {
    console.error('Error fetching chat data:', e.message);
    toast.error('Error fetching chat data:', e.message);
  } finally {
    loading.value = false;
  }
};

const sendMsg = async (msg) => {
  if (msg.trim().length > 0) {
    const messagePayload = {
      message: msg.trim(),
      is_draft: 0,
      category_id: msg.trim(),
      chat_id: topicId || 0, 
      message_id: '12',
      created_at: new Date().toISOString(), 
      isUser: true, 
    };
    
    console.log('sendMessage prop:', sendMessage);
    sendMessage(messagePayload);


    if (data.value && data.value.responseData) {
      data.value.responseData.push({
        id: new Date().getTime(), 
        message: messagePayload.message,
        created_at: messagePayload.created_at,
        isUser: messagePayload.isUser,
      });
    } else {
      data.value = {
        responseData: [
          {
            id: new Date().getTime(), 
            message: messagePayload.message,
            created_at: messagePayload.created_at,
            isUser: messagePayload.isUser,
          }
        ]
      };
    }
    console.log('Message sent:', messagePayload); 
    sentMessages.value.push(messagePayload.message);
  }
};

const sendPdf = async (msg) => {
  try {
    const result = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}send_mail`, {
        message:msg,
    });
    toast.success("Mail Sent successfully");
    console.log('PDF send request successful:', result); 
    return result;
  } catch (e) {
    toast.error(`Error: ${e.message}`);
    console.error('Error sending PDF:', e.message); 
  }
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text); 
    toast.success("Copied to Clipboard");
    console.log('Text copied to clipboard:', text); 
  } catch (err) {
    toast.error("Failed to copy text: ", err);
    console.error("Failed to copy text: ", err); 
  }
};

const toggleSpeak = async (message) => {
  if (message.isSpeaking) {
    stopSpeaking();
    message.isSpeaking = false;
  } else {
    data.value.responseData.forEach(message => {
      message.isSpeaking = false;
      message.isLoading = false;
    });
    message.isLoading = true;
    
    try {
      await speakText(message.message);
      message.isSpeaking = true;
    } catch (error) {
      console.error('Error speaking text:', error);
    } finally {
      
      message.isLoading = false;
      
    }
  }
};


const shareMessage = (message) => {
  if (navigator.share) {
    navigator.share({
      title: 'Shared Message',
      text: message,
    }).then(() => {
      console.log('Share was successful.')
      toast.success('Share was successful.');
    })
      .catch((error) => {
        console.error('Sharing failed:', error)
        toast.error('Sharing failed:', error);

      });
  } else {
    console.error('Sharing is not supported on this browser.');
    toast.error('Sharing is not supported on this browser.');
  }
};

const getBtn = async ()=>{
  try {
    const result = await JWTinterceptor.get(`${process.env.VUE_APP_SOFA_API}messages_map`, 
    {
        headers: {
          'x-access-tokens': token,
        },
      });
    
    // console.log(result.data); 
    buttons.value = result.data;

    transformedButtons.value = buttons.value.map((btn) => {
      const capitalized = btn
        .replace(/_/g, ' ') 
        .split(' ') 
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join(' '); 

      return { original: btn, label: capitalized };
    });
  } catch (error) {
    console.error('Error fetching buttons:', error);
  }
}
const handleButtonClick = (buttonText) => {
  text.value = buttonText;


  // Find the message that matches the button text
  // const existingMessage = data.value.responseData.find(
  //   (msg) => msg.message === buttonText
  // );

  // if (existingMessage) {
  //   console.log('Found existing message with id:', existingMessage.id);
  //   const messageElement = document.querySelector(
  //     `[data-id="${existingMessage.id}"]`
  //   );

  //   if (messageElement) {
  //     messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //     console.log('Scrolled to message with id:', existingMessage.id);
  //   } else {
  //     console.error('Message element with id', existingMessage.id, 'not found.');
  //   }
  // } else {
    sendMsg(buttonText); // If the message doesn't exist, send the new message
    console.log('Button clicked, message sent:', buttonText);
  // }
};

const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  });
};

watch(() => route.query.topicId, (newId) => {
  // topicId.value = newId
  store.commit('setTopicId', newId);
  // console.log(newId)
  getTopicChat(newId)
})
watch(data, (newVal, oldVal) => {
  if (newVal.responseData.length !== oldVal.responseData.length) {
    scrollToBottom();
  }
});



// const toggleDropdown = () => {
//   showDropdown.value = !showDropdown.value
// }

onUnmounted(() => {
  store.dispatch('websocket/closeConnection');

  // Stop speaking if any message is currently being spoken
  data.value.responseData.forEach((msg) => {
    if (msg.isSpeaking) {
      stopSpeaking(); // Stop the speaking process
      msg.isSpeaking = false; // Reset the speaking status for the message
    }
  });
});
</script>

<style scoped>
.card {
  background-color: #f8f9fa;
}
.timestamp {
  font-size: 0.85rem;
  color: white;
}
.message-body {
  font-size: 1rem;
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  /* width: 75%; */
}
.message-color-user{
  background-color: #1f86ae;
}
.message-color-sofia .timestamp, .message-color-sofia{
  background-color: #d1f8e5;
  color: #383939 !important;
}
.btn-color{
  color: #2481ad;
  background-color: transparent;
  border: none;
  font-size:1.7rem;
  margin-right: 20px;
}
.fixed-bottom{
  left:unset !important;
}
.dropdown-menu-up {
  top: auto;
  bottom: 100%;
  left: 0;
  transform: translateY(-10px);
  position: absolute;
  z-index: 1000;
}
ul{
  list-style-type: none;
  padding: 5px;
}
ul button{
  margin-bottom: 5px;
}
.dropup-menu {
  top: auto;
  bottom: 100%;
  left: 0;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
/* .nav-header{
    background-color: #1f86ae;
} */
</style>

