<template>
    <div class="d-flex justify-content-around justify-content-md-center nav-header fixed-top col-md-10">
        <div class="p-3 text-white toggle-btn d-block d-md-none" @click="toggleSidebar" >
            <i class="fa-solid fa-bars"></i>
        </div>
        <div class="my-3 text-white" v-if="patientName">
            <h5>Patient name: <span>{{ patientName }}</span></h5>
        </div>
        <img v-if="!patientName" class=" d-block d-md-none" src="./../assets/logo_sofia.png" alt="">
        <router-link :to="`/addEnconter`" class="text-decoration-none" >
        <button  class="add-topic-btn my-3 btn btn-primary d-block d-md-none">+ </button>
        </router-link>

    </div>
    <div>
        <SideNav v-if="isCollapsed" class="sideNav fixed-top"/>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import SideNav from './sideNav.vue'

// eslint-disable-next-line no-undef
defineProps({
  patientName: {
    type: String,
    required: true
  }
});
const isCollapsed = ref(false);

const toggleSidebar = () => {
  isCollapsed.value = !isCollapsed.value;
};

</script>
<style>
.nav-header {
  background-color: #1f86ae;
  left: unset;  
  right: unset;
  height: 60px;
}
.toggle-btn {
    text-align: center;
    cursor: pointer;
}
.fixed-top{
  left: unset !important; 
}

.sideNav{
    width: 90%;
    background-color: #f9f9f9;
}
.sidebar-collapsed .nav-link span {
    display: none;
}
@media (max-width: 576px) {
  .nav-header {
    width: 100%;
    justify-content: space-between;
    z-index: 1;
  }
.fixed-top{
  left: 0 !important;
  }
  
  }
</style>