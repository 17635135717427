<template>
  <div class="container vh-100 d-flex justify-content-center align-items-center">
    <div class="card shadow p-4" style="max-width: 400px; width: 100%;">
      <div class="text-center mb-4">
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid">
      </div>
      <form @submit.prevent="handleTwoFa">
        <div class="mb-3">
          <label for="code" class="form-label">Code</label>
          <input
            v-model="code"
            type="text"
            id="code"
            class="form-control"
            placeholder="Enter code"
            required
            @blur="touched.code = true"
          />
          <small v-if="touched.code && !code" class="text-danger">Code is required.</small>
          <small v-else-if="touched.code && code.length !== 4" class="text-danger">Code must be 4 characters.</small>
        </div>
        <button
          type="submit"
          class="btn btn-primary w-100"
          :disabled="!isFormValid"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const code = ref('');
const router = useRouter();
const route = useRoute();
const store = useStore();
const toast = useToast();
const touched = ref({
  code: false,
});

const isFormValid = computed(() => {
  return code.value.length === 4 ; 
});

onMounted(() => {
  const phone = store.getters.phone; 
  console.log('Received phone:', phone);
});

const handleTwoFa = async () => {
  try {
    const response = await store.dispatch('auth/handleTwoFa', {
      code: code.value,
      phone: store.getters.phone,
      from: route.query.from,
    });

    if (route.query.from === 'forget-password') {
      store.commit('setToken', response.token);
      router.push('/reset-password');
    } else {
      router.push('/');
    }
  } catch (error) {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode === 401) {
        toast.error('Not authorized ');
      } else if (statusCode === 403) {
        console.error = 'Code is incorrect';
        toast.error('Code is incorrect');
      } else {
        console.error = `Server error ${statusCode}`;
        toast.error(`Server error ${statusCode}`);
      }
    } else {
      console.error = 'An unexpected error occurred';
      toast.error('An unexpected error occurred');
    }
  }
};
</script>

<style scoped>
.card {
  border-radius: 8px;
}

.form-control {
  border-radius: 0.25rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
}

.text-danger {
  font-size: 0.875rem;
}
</style>
