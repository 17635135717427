// useSpeak.js
import axios from 'axios';
import { ref } from 'vue';

export function useSpeak(language ) {
  const isSpeaking = ref(false);
  let audioInstance = null;
  const isLoading = ref(true);

  
  console.log(isLoading)

  const speakText = async (text) => {
    const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; // replace with your API key
    const languageCode = language === 'en' ? 'es-US'  : 'en-US';
    const voiceName = language === 'en' ?'es-US-Neural2-A'  : 'en-US-Neural2-E';
    // console.log(languageCode)
    stopSpeaking();
    
    isLoading.value = true;
    const response = await axios.post(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
      {
        input: { text },
        voice: {
          languageCode,
          name: voiceName,
        },
        audioConfig: {
          audioEncoding: 'MP3',
          effectsProfileId: ['handset-class-device'],
          pitch: 0,
          speakingRate: 1,
        },
      }
    );

    const audioContent = response.data.audioContent;
    const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
    audioInstance = audio;
    isSpeaking.value = true;

    audio.play();
    audio.onended = () => {
      isSpeaking.value = false;
      isLoading.value = false;
    };
    audio.onerror = () => {
      isLoading.value = false; // Stop loading on error
    };
    audio.onplay = () => {
      isLoading.value = false;
    };
  };
  

  const stopSpeaking = () => {
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
      isSpeaking.value = false;
    }
  };

  return { speakText, stopSpeaking, isSpeaking,isLoading };
}
