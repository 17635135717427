<template>
  <div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card p-4" style="max-width: 400px; width: 100%;">
      <div class="text-center mb-4">
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid">
      </div>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input 
            v-model="email" 
            type="email" 
            id="email" 
            class="form-control" 
            placeholder="Enter your email" 
            required
            @blur="touched.email = true"
          />
          <small v-if="touched.email && !email" class="text-danger">Email is required.</small>
            <small v-else-if="touched.email && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input 
              v-model="password" 
              :type="showPassword ? 'text' : 'password'"
              id="password" 
              class="form-control" 
              placeholder="Enter your password" 
              required
              @blur="touched.password = true"
            />
            <div class="input-group-append">
              <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                <i @click="toggleShow" class="fas eye-shape"
                  :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </span>
            </div>
          </div>
          <small v-if="touched.password && !password" class="text-danger">Password is required.</small>
          <small v-else-if="touched.password && password.length < 6" class="text-danger">Password must be at least 6 characters.</small>

        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <a href="/forget-password" class="link-primary">Forget your password?</a>
          <button type="submit" class="btn btn-primary" :disabled="!isFormValid || loading">Login</button>
        </div>
        
        <p class="text-center">Don't have an account? <a href="/register" class="link-primary">Signup</a></p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref , computed} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const touched = ref({
  email: false,
  password: false,
});
const error = ref('');
const router = useRouter();
const store = useStore();
const loading = ref(false);
const toast = useToast();
let showPassword = ref(false);

const isFormValid = computed(() => {
  return  isEmailValid.value &&  password.value.length >= 6 
});
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email.value);
});
const toggleShow = () => {
            showPassword.value = !showPassword.value;
        };
const login = async () => {
  loading.value = true;
  error.value = '';

  try {
    await store.dispatch('auth/login', {
      email: email.value,
      password: password.value,
    });
    router.push('/');
  } catch (err) {
    if (err.response) {
      const statusCode = err.response.status;
      const message = err.response.data.message;
      if (statusCode === 401 && message == "user exists but not active") {
        
        router.push('/otp');
      } else if (statusCode === 400) {
        error.value = 'Please try again later.';
        toast.error("Please try again later.");
      } else if (statusCode === 401) {
        error.value = 'The password or email is incorrect.';
        toast.error("The password or email is incorrect.");
      } else {
        error.value = `Server error ${statusCode}`;
        toast.error(`Server error ${statusCode}`);

      }
    } else {
      error.value = 'Please try again later.';
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.link-primary {
  color: #007bff;
}

.link-primary:hover {
  color: #0056b3;
}
</style>
