<template>
    <div>
      <h1>Launching Application...</h1>
      <p>Please wait while we process your session.</p>
    </div>
  </template>
  
<script setup>
  import { onMounted , } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();
  const urlParams = new URLSearchParams(window.location.search);
  const launch = urlParams.get('launch');
  const iss = urlParams.get('iss');
  // const sfhirToken = ref('');
  // const ehrPatientId = ref('');
  // const ehrOrgName = ref('');
onMounted(() => {
  if (!launch || !iss) {
    console.error('Required parameters (launch or iss) are missing from the URL.');
    return;
  }

  const allowedIssList = [
    'https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4',
  ];

  if (!allowedIssList.includes(iss)) {
    console.error('ISS value is not in the allowlist. Potential phishing attempt.');
    return;
  }
  sendToBackend();
});

const sendToBackend = async () => {
    console.log("ttt")
      const response = await fetch('https://ai-stage.ensofia.app:8443/ehr_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ launch, iss }),
      });
      console.log(JSON.stringify({ launch, iss }))
      const responseData = await response.json();
      // const ehrPatientId =responseData.patientId;
      // const ehrOrgName= responseData.organizationName;
      // const ehrEncounterId =responseData.encounter;
      // const ehrDoctorName = responseData.practitioner.first_name;
      console.log('Backend response:', responseData);
      // const ehrPatientId = responseData.patientId;
      // const ehrOrgName = ref('');
      if (!response.ok) {
        sfhirLogin(/*ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName*/);
        // throw new Error('Failed to send data to the backend.');
      }
      else{
        // sfhirLogin()
      }

 
  };
  const sfhirLogin = async (/*ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName*/) => {
    console.log("sfhirLogin")
    const basicAuth = btoa(`${'integrator@ensofia.com'}:${'AnalYtics$2420'}`);
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}sfhir_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${basicAuth}`,
        },
      });
      const responseData = await response.json();
      const sfhirToken = responseData.access_token;
      console.log(responseData)
      if (!response.ok) {
        // throw new Error('Failed to send data to the backend.');
      }
      else{
        getPatientData(sfhirToken,/*ehrPatientId,ehrOrgName,ehrEncounterId,ehrDoctorName*/);
      }
      
  }
  const getPatientData = async (sfhirToken,/*ehrPatientId,ehrOrgName,*/ehrEncounterId,ehrDoctorName) => {
    console.log("tokens",sfhirToken)
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}patient_ehr_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sfhirToken}`,
        },
        body: JSON.stringify({
          patientId:"erXuFYUfucBZaryVksYEcMg3",
          organizationName:"Epic Integration"
          // patientId:ehrPatientId,
          // organizationName:ehrOrgName
        }),
      });
      const responseData =await response.json();
      console.log("patient",responseData)
      if (response.ok) {
        router.push({ name: 'encounter', query: { ehrEncounterId, ehrDoctorName  } });
      }
  }

</script>



