<template>
  <div class="chat-display">
    <h3>{{ title }}</h3>
    <div class="messages">
      <div v-for="message in messages" :key="message.id" class="message">
        <strong>{{ message.userType }}:</strong> {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: Array,
    title: String,
  },
};
</script>

<style scoped>
.chat-display {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 450px;
  max-height: 450px;
  overflow-y: auto;
}
.messages {
  display: flex;
  flex-direction: column;
}
.message {
  margin-bottom: 10px;
}
</style>
