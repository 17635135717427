import { useToast } from 'vue-toastification';
import { ref, onMounted, onUnmounted } from 'vue';

export function useConnectionStatus() {
  const toast = useToast();
  const isOnline = ref(navigator.onLine);
  let offlineToastId = null; // Store the ID of the offline toast

  const updateOnlineStatus = () => {
    const newStatus = navigator.onLine;
    if (isOnline.value !== newStatus) {
      isOnline.value = newStatus;

      if (newStatus) {
        // Connection restored: dismiss offline toast and show success
        if (offlineToastId) {
          toast.dismiss(offlineToastId);
          offlineToastId = null; // Reset the toast ID
        }
        toast.success('You are back online!', {
          timeout: 3000,
        });
      } else {
        // Connection lost: show persistent error toast
        offlineToastId = toast.error(
          'Lost Internet Connection. Please check your network.',
          {
            timeout: false,
          }
        );
      }
    }
  };

  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  onUnmounted(() => {
    window.removeEventListener('online', updateOnlineStatus);
    window.removeEventListener('offline', updateOnlineStatus);
  });

  return { isOnline };
}
