// useTranslation.js
import axios from 'axios';
import { useToast } from 'vue-toastification';
const toast = useToast();

export async function translateText(text, language) {
  const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; 
  const targetLanguage = language === 'en' ? 'es' : 'en';

  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2`,
      null,
      {
        params: {
          q: text,
          target: targetLanguage,
          key: apiKey,
        },
      }
    );
    if (response.data.data && response.data.data.translations) {
      return response.data.data.translations[0].translatedText;
    } else {
      throw new Error('No translation results');
    }
  } catch (err) {
    toast.error('Translation error: ' + err.message);
    throw err;
  }
}
